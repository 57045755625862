<template>
  <!-- <div> -->
  <v-layout justify-center row wrap align-center>
    <welcome />
    <!-- <recent-projects /> -->
    <!-- <services /> -->
    <!-- <about-me /> -->
    <!-- <get-in-touch /> -->
  </v-layout>
  <!-- </div> -->
</template>

<script>
export default {
  components: {
    // AboutMe: () => import('@/components/AboutMe'),
    // GetInTouch: () => import('@/components/GetInTouch'),
    // RecentProjects: () => import('@/components/RecentProjects'),
    // Services: () => import('@/components/Services'),
    Welcome: () => import('@/components/Welcome')
  }
}
</script>
